import React from 'react';
import { graphql } from 'gatsby';
import PizzaList from '../components/PizzaList';
import ToppingsFilter from '../components/ToppingsFilter';
import SEO from '../components/SEO';

const PizzasPage = ({
  data: {
    pizzas: { nodes: pizzas },
  },
  pageContext: { toppingName },
}) => (
  <>
    <SEO title={toppingName ? `Pizzas with ${toppingName}` : 'All Pizzas'} />
    <ToppingsFilter />
    <PizzaList pizzas={pizzas} />
  </>
);

export default PizzasPage;

export const query = graphql`
  query PizzaQuery($regex: String) {
    pizzas: allSanityPizza(
      filter: { toppings: { elemMatch: { name: { regex: $regex } } } }
    ) {
      nodes {
        name
        id
        slug {
          current
        }
        price
        toppings {
          id
          name
        }
        image {
          asset {
            fixed(width: 600, height: 200) {
              ...GatsbySanityImageFixed
            }
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
