import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const PizzaGridStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 4rem;
  grid-auto-rows: auto auto 500px;
`;

const PizzaStyles = styled.div`
  display: grid;
  /* Take row sizing not from pizzaStyles dv but from th PizzaGridStyles grid */
  @supports not (grid-template-rows: subgrid) {
    --rows: auto auto 1fr;
  }
  grid-template-rows: var(--rows, subgrid);
  grid-row: span 3;
  grid-gap: 1rem;
  h2,
  p {
    margin: 0;
  }
`;

const formatMoney = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format;

export const SinglePizza = ({ pizza }) => (
  <PizzaStyles>
    <Link to={`/pizza/${pizza.slug.current}`}>
      <h2>
        <span className="mark">{pizza.name}</span> -{' '}
        {pizza.price ? formatMoney(pizza.price / 100) : 'Free Today!'}
        {pizza.toppings.reduce(
          (isVeg, curr) => curr.vegetarian !== false && isVeg,
          true
        )
          ? ' 🥬'
          : ''}
      </h2>
    </Link>
    <p>
      {pizza.toppings
        .map((topping) => topping.name + (topping.vegetarian ? ' 🥬' : ''))
        .join(', ')}
    </p>
    <Img fluid={pizza.image.asset.fluid} alt={pizza.name} />
  </PizzaStyles>
);

const PizzaList = ({ pizzas }) => (
  <PizzaGridStyles>
    <title>Pizzas!!</title>
    {pizzas.map((pizza) => (
      <SinglePizza pizza={pizza} key={pizza.id} />
    ))}
  </PizzaGridStyles>
);

export default PizzaList;
