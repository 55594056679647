import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const ToppingStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 4rem;
  a {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    font-size: clamp(1.5rem, 1.4vw, 2.5rem);
    border-radius: 2px;
    padding: 5px;
    background: var(--grey);
    margin:auto;
    .count {
      background: white;
      padding 2px 5px;
    }
    &[aria-current='page']{
      background: var(--yellow);
    }

  }
`;

const countPizzasInToppings = (pizzas) => {
  const counts = pizzas
    .map((pizza) => pizza.toppings)
    .flat()
    .reduce((acc, topping) => {
      const existingTopping = acc[topping.id];
      if (existingTopping) {
        acc[existingTopping.id].count += 1;
      } else {
        acc[topping.id] = {
          id: topping.id,
          name: topping.name,
          count: 1,
        };
      }
      return acc;
    }, {});
  const sortedToppings = Object.values(counts).sort(
    (a, b) => b.count - a.count
  );
  return sortedToppings;
};

const ToppingsFilter = () => {
  const {
    pizzas: { nodes: pizzas },
  } = useStaticQuery(graphql`
    {
      pizzas: allSanityPizza {
        nodes {
          toppings {
            name
            id
          }
        }
      }
    }
  `);
  const toppingsWithCounts = countPizzasInToppings(pizzas);
  return (
    <ToppingStyles>
      <Link to="/pizzas">
        <span className="name">All</span>
        <span className="count">{pizzas.length}</span>
      </Link>
      {toppingsWithCounts.map((topping) => (
        <Link to={`/topping/${topping.name}`} key={topping.id}>
          <span className="name">{topping.name}</span>
          <span className="count">{topping.count}</span>
        </Link>
      ))}
      <Link to="/beers">
        <h2>We have Beers too!</h2>
      </Link>
    </ToppingStyles>
  );
};

export default ToppingsFilter;
